import { isLoading } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import { APP_CAREPROVIDER } from "core/consts";
import { activateEnvSwitch, isNewTheme } from "core/model/utils/featureFlags";
import { useManualSetLegalDocuments } from "dsl/atoms/LegalDocuments";
import { LoginPageView } from "dsl/ecosystems/LoginPage";
import { useHandleLogin } from "dsl/ecosystems/LoginPage/useHandleLogin";
import { useLoginPage } from "dsl/ecosystems/LoginPage/useLoginPage";
import { LoginPageV2 } from "dsl/ecosystems/LoginPageV2";
import {
  loginPageModelDefinition,
  validateChallenge,
  validateLogin,
} from "dsl/ecosystems/LoginPageV2/formConfig";
import { useCareproviderNavigationHandlers } from "dsl/hooks";
import { useCareproviderHandlerUrls } from "dsl/hooks/useNavigationHandlers";
import { SimpleFormRenderProps } from "react-forms-state/src";

export function CareproviderLoginPage({
  careproviderToken,
  careproviderToLog,
}: {
  careproviderToLog?: number;
  careproviderToken?: string;
}) {
  const { env } = useEnvContext();
  const {
    challenge,
    email,
    error,
    loginProgress,
    module,
    oauthToken,
    onLogin,
    password,
    setChallenge,
    setEmail,
    setModule,
    setPassword,
    status,
  } = useLoginPage({ careproviderToken, careproviderToLog });
  const {
    challengeTimestamp,
    handleLogin,
    isLogin,
    loginErrors,
    loginView,
    progressLogin,
    resetError,
    setLoginView,
    setProgressLogin,
  } = useHandleLogin({ careproviderToLog });

  const { goToForgotPassword: goToForgotPasswordHandler } =
    useCareproviderNavigationHandlers();
  const {
    goToContactUs,
    goToForgotPassword,
    goToProductionEnv,
    goToSenderApp,
  } = useCareproviderHandlerUrls();

  // Setting the legal documents to default on the login screen, otherwise
  // it tries to fetch with the id (logout/onboarding), although logged out
  useManualSetLegalDocuments();

  return isNewTheme() ? (
    <main>
      <SimpleFormRenderProps
        asHtmlForm
        onSubmit={(props) => handleLogin(props, setProgressLogin)}
        modelDefinition={loginPageModelDefinition}
        validate={isLogin ? validateLogin : validateChallenge}
      >
        {({ onChange, submit }) => (
          <LoginPageV2
            app={APP_CAREPROVIDER}
            challengeTimestamp={challengeTimestamp}
            contactUsUrl={goToContactUs()}
            env={env}
            goToForgotPassword={goToForgotPassword()}
            goToProductionUrl={goToProductionEnv()}
            handleLogin={(props) => handleLogin(props, setProgressLogin)}
            isLoading={isLoading(progressLogin)}
            loginErrors={loginErrors}
            loginView={loginView}
            onBackToLogin={() => {
              onChange(null, "challenge", true);
              onChange(null, "email", true);
              onChange(null, "password", true);
              resetError();
              setLoginView("login");
            }}
            onChangeFormValues={onChange}
            onSubmit={submit}
            otherAppUrl={goToSenderApp()}
            resetError={resetError}
            withDevTools={activateEnvSwitch}
          />
        )}
      </SimpleFormRenderProps>
    </main>
  ) : (
    <LoginPageView
      challenge={challenge}
      email={email}
      error={error ?? null}
      module={module}
      onLogin={onLogin}
      onLoginQueryProgress={loginProgress}
      password={password}
      passwordRequired={!oauthToken}
      setChallenge={setChallenge}
      setEmail={setEmail}
      setModule={setModule}
      setPassword={setPassword}
      status={status}
      onPasswordResetPage={() => goToForgotPasswordHandler()}
    />
  );
}
