import { useEnvContext } from "context/EnvContext";
import { APP_CLINIC } from "core/consts";
import { getPatientName } from "core/model/patients";
import { Patient } from "core/types";
import InfoCard, { CardContentContainer } from "ds_legacy/components/InfoCard";
import RSButton from "ds_legacy/components/RSButton";
import {
  dp,
  margin,
  padding,
  space,
  TABS_HEIGHT,
} from "ds_legacy/materials/metrics";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
} from "ds_legacy/materials/typography";
import React, { MouseEvent, useCallback } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";

export const BoxLayout = styled.div`
  box-sizing: border-box;
  @media screen {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    max-width: ${dp(750)};
    padding-top: ${space(2)};
  }

  @media screen {
    & {
      padding-left: ${space(11)};
    }
  }

  @media screen and (max-width: 1360px) {
    & {
      padding-left: ${space(3)};
    }
  }

  @media screen and (max-width: 760px) {
    & {
      padding-left: 0px;
      width: 100vw;
    }
  }
`;

export const InfosBox = styled(BoxLayout)`
  @media screen {
    padding-bottom: ${space(15)};
    padding-right: ${space(0)};
    align-items: stretch;
    flex-direction: column;
  }
`;

export const InfosPartBox = styled.div<{ marginBottom?: number }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: ${({ marginBottom }) => margin(0, 0, marginBottom || 1, 0)};
`;

export const ServicesBox = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  margin: 0;
  .facility-circle {
    margin: ${0};
  }
`;

export const FieldsBox = styled.div<{
  column?: boolean;
  fit?: boolean;
  flexWrap?: boolean;
  grow?: boolean;
  justifyContent?: string;
  margin?: string | false;
  noBasis?: boolean;
  unshrink?: boolean;
  width?: string;
}>`
  display: ${(props) => (props.fit ? "inline-block" : "flex")};
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  flex-shrink: ${(props) => (props.unshrink ? "0" : "1")};
  flex-grow: ${(props) => (props.grow ? "1" : "0")};
  flex-basis: ${(props) => (props.noBasis ? "0px" : "auto")};
  justify-content: ${(props) => props.justifyContent};
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "nowrap")};
  align-content: flex-start;
  width: ${(props) => props.width};
  margin: ${(props) => props.margin || margin(0, 0, 2, 0)};
  word-break: break-word;
`;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: ${TABS_HEIGHT};
  justify-content: space-between;
  padding: ${padding(0.5)};
  width: 100%;
`;

export const DisplayUserIdOrPatientName = ({
  bold,
  hyphen,
  parenthesis,
  patient,
  prefix,
  showName,
  translations,
}: {
  bold?: boolean;
  hyphen?: boolean;
  parenthesis?: boolean;
  patient: Patient | null | undefined;
  prefix: string | null | undefined;
  showName: boolean;
  translations: Translations;
}) => {
  const patientName = (showName && patient && getPatientName(patient)) || null;
  const userId = patient?.user_id;
  const fontWeight = bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR;
  const prefixStyle = { style: { fontWeight: FONT_WEIGHT_REGULAR } };
  const userIdSlug = (content: string) =>
    userId ? (parenthesis ? ` (${content})` : " " + content) : null;

  const join = hyphen ? " - " : " ";

  return patientName ? (
    <span
      style={{
        fontWeight,
        textTransform: "none",
      }}
    >
      {prefix && <span {...prefixStyle}>{`${prefix}${join}`}</span>}
      {patientName}
      {userId && <span>{` (${userId})`}</span>}
    </span>
  ) : (
    <>
      {prefix && <span {...prefixStyle}>{prefix}</span>}
      <span style={{ fontWeight }}>
        {" "}
        {userIdSlug(`${translations.patient.patient} ${userId}`)}
      </span>
    </>
  );
};

export const ActionBanner = ({
  action,
  actions,
  noBorder,
  patient,
  text,
}: {
  action: (() => void) | undefined;
  actions?: Array<React.ReactNode>;
  noBorder?: boolean;
  patient?: Patient | null | undefined;
  text: string;
}) => {
  const translations = useTranslations();
  const { app } = useEnvContext();

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (action) action();
    },
    [action],
  );

  return (
    <InfoCard cardMargin="0px 0px -1px 0px" noBorder={noBorder}>
      <CardContentContainer noPadding noBorder={noBorder}>
        <Container>
          <RSButton
            color="grey"
            id="back_to_search"
            LeftIcon="back"
            loading="na"
            onClick={onClick}
            style={{ margin: margin(0) }}
            variant="text"
          >
            <div style={{ width: "100%" }}>
              <DisplayUserIdOrPatientName
                showName={app === APP_CLINIC}
                patient={patient}
                translations={translations}
                prefix={text}
                hyphen
                parenthesis
                bold
              />
            </div>
          </RSButton>
          <div style={{ display: "flex", alignItems: "center" }}>{actions}</div>
        </Container>
      </CardContentContainer>
    </InfoCard>
  );
};
